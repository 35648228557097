import React from "react";
import "./portfolio.css";
import PortfolioCard from "./portfolio-card/portfolio-card";

const Portfolio = () => {
  return (
    <div id="portfolio" className="portfolio">
      <div className="portfolio-container">
        <h1 className="section-heading">Portfolio</h1>
        <span className="portfolio-sub-heading">
          Dale un vistazo a mis últimos proyectos
        </span>
        <div className="container3">
          <PortfolioCard
            href="https://hotelcasadelaspalmas.com"
            image_src="assets/casadelaspalmas-website.jpg"
            subtitle="Hotel Casa De Las Palmas (Website)"
          ></PortfolioCard>
          <PortfolioCard
            href="https://nuapp-web.onrender.com"
            image_src="assets/quente_logo.jpg"
            subtitle="Quente (WebApp)"
          ></PortfolioCard>
          <PortfolioCard
            href="/"
            image_src="assets/corte_logo.jpg"
            subtitle="Corte (WebApp) Coming soon!😉"
          ></PortfolioCard>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
